body, html {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../public/background.png');
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none; /* Remove underlines and decorations */
  color: inherit; /* Inherit the color from the parent element (usually the default button color) */
}

a:hover {
  text-decoration: none; /* Remove underlines and decorations on hover */
  color: inherit; /* Inherit the color on hover */
}